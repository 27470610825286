import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import alim from "./alim.json"
import constantes from "./constantes.json"
import compo from "./compo.json"
import { FoodDB } from "./foodDB"

console.log("Starting to fetch")
const t0 = window.performance.now()

// function convertThoseStupidTables(table) {
// 	const damnCollection = table.children
	
// 	return Array.from(damnCollection).map(element => {
// 	    let object = {}

// 	    for (let child of element.children) {
// 		if (!child.attributes["missing"]) {
// 		    object[child.tagName] = child.childNodes[0].nodeValue.trim()
// 		}
// 	    }

// 	    // console.log(object)
// 	    return object
// 	})
//     }


// var    const_data = fetch(const_data_file)
//     .then(r => {
// 	const t = window.performance.now()
	
// 	console.log(`Const file has been fetched and it took ${t - t0} ms`)
// 	return r
//     } )

//     .then(r => r.arrayBuffer())
//     .then(buffer => {
// 	const decoder = new TextDecoder("windows-1252")
// 	const text = decoder.decode(buffer)

// 	return text
//     })
// 	.then(xml => xml.replaceAll(/<\d|<\s/g, "--"))
// 	.then(xml => xml.replaceAll(/&/g, "(and)"))
// 	.then(xml => {

// 	    const parser = new DOMParser

// 	    const data = parser.parseFromString(xml, "application/xml")
// 	    const table = data.children[0]
	    
	    
// 	    const t = window.performance.now()
	
// 	    console.log(`Const file has been parsed and it took ${t - t0} ms`)

// 	    // const_data = data
// 	    return convertThoseStupidTables(table)
// 	})

// var    compo_data = fetch(compo_data_file)
//     .then(r => {
// 	const t_compo = window.performance.now()
	
// 	console.log(`Composition file has been fetched and it took ${t_compo - t0} ms`)
// 	return r
//     } )
//     .then(r => r.arrayBuffer())
//     .then(buffer => {
// 	const decoder = new TextDecoder("windows-1252")
// 	const text = decoder.decode(buffer)

// 	return text
//     })
// 	.then(xml => xml.replaceAll(/<\d|<\s/g, "--"))
// 	.then(xml => xml.replaceAll(/&/g, "(and)"))
// 	.then(xml => {

// 	    const parser = new DOMParser

// 	    const data = parser.parseFromString(xml, "application/xml")
// 	    const table = data.children[0]
	    
// //	    console.log(table)

// 	    const t = window.performance.now()
	
// 	    console.log(`Composition file has been parsed and it took ${t - t0} ms`)

// 	    return convertThoseStupidTables(table)
// 	})

// var    alim_data = fetch(alim_data_file)
//     .then(r => {
// 	const t = window.performance.now()
	
// 	console.log(`Alim file has been fetched and it took ${t - t0} ms`)
// 	return r
//     } )

//     .then(r => r.arrayBuffer())
//     .then(buffer => {
// 	const decoder = new TextDecoder("windows-1252")
// 	const text = decoder.decode(buffer)

// 	return text
//     })

//     // .then(xml => { console.log(xml) ; return xml })
//     .then(xml => xml.replaceAll(/<\d|<\s/g, "--"))
// 	.then(xml => xml.replaceAll(/&/g, "(and)"))
// 	.then(xml => {

// 	    const parser = new DOMParser

// 	    const data = parser.parseFromString(xml, "application/xml")
// 	    const table = data.children[0]
	    
// 	    // console.log(table)

// 	    const t = window.performance.now()
	
// 	    console.log(`Alim file has been parsed and it took ${t - t0} ms`)

// 	    return convertThoseStupidTables(table)
// 	})




// const alim_data =  fetch(alim_data_file).then(r => r.json())
// const const_data = fetch(const_data_file).then(r => r.json())
// const compo_data = fetch(compo_data_file).then(r => r.json())

// fetch(alim_data_file).then(r => r.text()).then(t => console.log(t))
// fetch("./const.json").then(r => r.text()).then(t => console.log(t))
// fetch("./compo.json").then(r => r.text()).then(t => console.log(t))

// Promise.all([alim_data, const_data, compo_data]).then((values) => {



// const [alim, constantes, compo] = [JSON.parse(alim_data_file),
// 				   JSON.parse(const_data_file),
// 				   JSON.parse(compo_data_file)]

    const t_all = window.performance.now()

    console.log(`All fetching is complete and it took ${t_all - t0} ms`)

    // const makeFile = (json) => {
    // 	const blob = new Blob([json], { type: 'text/plain' })

    // 	return window.URL.createObjectURL(blob)
    // }

    // const makeDownload = (href, filename) => {
    // 	const link = document.createElement('a')

    // 	link.setAttribute('download', filename)
    // 	link.href = href
    // 	link.text = filename
    // 	document.body.appendChild(link)
    // }
    
    // makeDownload(makeFile(JSON.stringify(compo)), 'compo.json')
    // makeDownload(makeFile(JSON.stringify(alim)), 'alim.json')
    // makeDownload(makeFile(JSON.stringify(constantes)), 'constantes.json')



// for (const element of a) {
//     console.log(element)
// }

var foodDB = new FoodDB(alim, constantes, compo)


	ReactDOM.render(
	    <React.StrictMode>
		<App/>
	     </React.StrictMode>,
	    document.getElementById('root')
	);



    // })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

export { foodDB } ;
