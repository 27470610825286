import Fuse from 'fuse.js'
// [{name, value}, ...]

String.prototype.reverse = function() {
    return this.split("").reverse().join("")
}

function addMethodsToNutrientObject(nutrient) {
    const parseUnit = function() {
	let extract_unit = /^\)(?<unit>.*?)\(/
	
	const result = extract_unit.exec(this.name.reverse())


	const unit = result.groups["unit"].reverse()

	// console.log(result)
	// console.log(unit)
	return unit
    }

    const parseUnitWithoutMass = function() {
	let extract_unit = /^\).*?\/(?<unit>.*?)\(/
	
	const result = extract_unit.exec(this.name.reverse())


	const unit = result.groups["unit"].reverse()

	// console.log(result)
	// console.log(unit)
	return unit
    }

    const nameWithoutUnit = function() {
	return this.name.reverse().replace(/^\).*?\(/g, "").reverse().trim()
    }

    nutrient.parseUnit = parseUnit
    nutrient.parseUnitWithoutMass = parseUnitWithoutMass
    nutrient.nameWithoutUnit = nameWithoutUnit
    return nutrient
}

function plainArrayToNutrientArray(array) {
    const findNutrient = function (name) {
	let nutrient = this.find((nutrient) => nutrient.name === name)

	return nutrient
    }

    const findNutrientIndex = function (name) {
	return this.findIndex((nutrient) => nutrient.name === name)
    }
    
    const toProrataOfQuantity = function (quantity) {
	// As not to loose the NutrientArray status of the object
	return plainArrayToNutrientArray(this.map((nutrient) => {
	    const value = nutrient.value * quantity;

	    return addMethodsToNutrientObject({
		name: nutrient.name,
		value
	    })
	}))
    }

    const sum = function(rhs) {
	for (const nutrient of rhs) {
	    const index = this.findNutrientIndex(nutrient.name)
	    const value = isNaN(nutrient.value) ? 0.0 : nutrient.value

	    if (index == -1) {
		let new_nutrient = {
		    ...nutrient
		}

		new_nutrient.value = value

		addMethodsToNutrientObject(new_nutrient)
		
		this.push(new_nutrient)
	    } else {
		this[index].value += value
	    }
	}
	return this
    }

    // Purely used to add zero-entries when nutrient info is lacking
    const addMissingEntries = function(template) {
	for (const nutrient of template) {
	    const index = this.findNutrientIndex(nutrient.name)

	    if (index == -1) {
		let new_nutrient = {
		    ...nutrient,
		}

		new_nutrient.value = 0.0

		addMethodsToNutrientObject(new_nutrient)
		
		this.push(new_nutrient)
	    }
	}
	return this
    }

    for (let nutrient of array) {
	addMethodsToNutrientObject(nutrient)
    }

    array.findNutrient = findNutrient
    array.findNutrientIndex = findNutrientIndex
    array.toProrataOfQuantity = toProrataOfQuantity
    array.sum = sum
    array.addMissingEntries = addMissingEntries

    return array
}
    

class FoodDB {
    foods = {}
    constituants = {}
    compositions = {}

    
    constructor(foods, constituants, compositions) {
	const fuse_options = {
	    findAllMatches: true,
	    keys: [
		"alim_nom_fr",
		"ALIM_NOM_INDEX_FR",
	    ]
	}
	
	this.foods = foods
	this.constituants = constituants
	this.compositions = compositions
	this.fuse = new Fuse(foods, fuse_options)
    }

    nutrients(name) {
	const alim_code = this.foods.find(a => { return a.alim_nom_fr === name } ).alim_code

	
	let composition = Array.from(this.compositions.filter(compo => compo.alim_code === alim_code).map(comp => {
	    const constituant = this.constituants.find(constituent => constituent.const_code === comp.const_code).const_nom_fr

	    let value = parseFloat(comp.teneur.replace(',', '.'))

	    value = isNaN(value) ? 0.0 : value //TODO: We should handle less_than some value entries someday
	    
	    return {
		name: constituant,
		value: value,
	    }
	}))

	return plainArrayToNutrientArray(composition)
    }


    
    nutrient(name, nutrient) {
	return this.nutrients(name).findNutrient(name) // Elegant but costly. Well whatever I guess
    }

    search(name) {
	return this.fuse.search(name)
    }

    addCustomFood(name, nutrients) {
	
    }

    addCustomRecipe(foods) {
	
    }
}

export { FoodDB, plainArrayToNutrientArray };
