// "Fluor (mg/j)": {
//     "Nourrissons de moins de 6 mois": {		AS: 0,08,		LSS: undefined	}, 
//     "Nourrissons de 6 mois et plus": {		AS: 0,4,		LSS: undefined	}, 
//     "Enfants de 1 à 3 ans": {			AS: 0,6,		LSS: 1,5	}, 
//     "Garçons de 4 à 6 ans": {			AS: 1,0,		LSS: 2,5	}, 
//     "Filles de 4 à 6 ans": {			AS: 0,9,		LSS: 2,5	}, 
//     "Garçons de 7 à 10 ans": {			AS: 1,5,		LSS: 5		}, 
//     "Filles de 7 à 10 ans": {			AS: 1,4,		LSS: 5		}, 
//     "Adolescents de 11 à 14 ans": {		AS: 2,2,		LSS: 7		}, 
//     "Adolescentes de 11 à 14 ans": {		AS: 2,3,		LSS: 7		}, 
//     "Adolescents de 15 à 17 ans": {		AS: 3,2,		LSS: 7		}, 
//     "Adolescentes de 15 à 17 ans": {		AS: 2,8,		LSS: 7		}, 
//     "Hommes de 18 ans et plus": {		AS: 3,4,		LSS: 7		}, 
//     "Femmes de 18 ans et plus": {		AS: 2,9,		LSS: undefined	}, 
//     "Femmes enceintes ou allaitantes": {	AS: 2,9,		LSS: undefined	}, 
// }
// for (let table of Array.from(document.getElementsByClassName("Table"))) { 
//         console.log(table.getElementsByTagName("tr")..map((row, row_index) => row.getElementsByTagName("td").filter((data, data_index) => data_index === table_header_index)))
// }


const data = [{    "Nourrissons de moins de 6 mois": {		AS: 0.08,		LSS: undefined	}}, 
{    "Nourrissons de 6 mois et plus": {		AS: 0.4,		LSS: undefined	}}, 
{    "Enfants de 1 à 3 ans": {			AS: 0.6,		LSS: 1.5	}}, 
{    "Garçons de 4 à 6 ans": {			AS: 1.0,		LSS: 2.5	}}, 
{    "Filles de 4 à 6 ans": {			AS: 0.9,		LSS: 2.5	}}, 
{    "Garçons de 7 à 10 ans": {			AS: 1.5,		LSS: 5		}}, 
{    "Filles de 7 à 10 ans": {			AS: 1.4,		LSS: 5		}}, 
{    "Adolescents de 11 à 14 ans": {		AS: 2.2,		LSS: 7		}}, 
{    "Adolescentes de 11 à 14 ans": {		AS: 2.3,		LSS: 7		}}, 
{    "Adolescents de 15 à 17 ans": {		AS: 3.2,		LSS: 7		}}, 
{    "Adolescentes de 15 à 17 ans": {		AS: 2.8,		LSS: 7		}}, 
{    "Hommes de 18 ans et plus": {		AS: 3.4,		LSS: 7		}}, 
{    "Femmes de 18 ans et plus": {		AS: 2.9,		LSS: undefined	}}, 
{    "Femmes enceintes ou allaitantes": {	AS: 2.9,		LSS: undefined	}}, ]


HTMLCollection.prototype.toArray = function () { return Array.from(this) }

Array.prototype.merge = function () {
    let object = { }

    for (const entry of this) {
	for (const [key, value] of Object.entries(entry)) {
	    object[key] = value
	}
    }
    return object
}



// console.log(data.merge())


const tables = document.getElementsByClassName("Table").toArray()


function nutritionTableToReferenceObject(table) {
    const rows = table.getElementsByTagName('tr').toArray()
    const headers = rows[0].getElementsByTagName('td').toArray().slice(1)

    const assoc = headers.map(h => h.textContent.trim())

    return rows.slice(1).map(row => {
        const data = row.getElementsByTagName('td').toArray()
        const entry_name = data[0].textContent.trim()


	return {
            [entry_name] : data.slice(1).map((data, index) => {
		
		return {
                    [assoc[index]]: Number(data.textContent.trim()) === 0 ? null : Number(data.textContent.trim()),
		    
		}}).merge()
	}}).merge()
}


// console.log(tables.map(nutritionTableToReferenceObject))

// document.querySelectorAll("h3 ~ table").toArray().slice(4).map(h => h.textContent)


let references = {
    "Vitamine A": {
        "Nourrissons de moins de 6 mois": {
            "AS": 350
        },
        "Nourrissons de 6 mois et plus": {
            "BNM": 190,
            "RNP": 250
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 205,
            "RNP": 250,
            "LSS": 800
        },
        "Enfants de 4 à 6 ans": {
            "BNM": 245,
            "RNP": 300,
            "LSS": 1100
        },
        "Enfants de 7 à 10 ans": {
            "BNM": 320,
            "RNP": 400,
            "LSS": 1500
        },
        "Adolescents de 11 à 14 ans": {
            "BNM": 480,
            "RNP": 600,
            "LSS": 2000
        },
        "Adolescents de 15 à 17 ans": {
            "BNM": 580,
            "RNP": 750,
            "LSS": 2600
        },
        "Adolescentes de 15 à 17 ans": {
            "BNM": 490,
            "RNP": 650,
            "LSS": 2600
        },
        "Hommes de 18 ans et plus": {
            "BNM": 580,
            "RNP": 750,
            "LSS": 3000
        },
        "Femmes de 18 ans et plus": {
            "BNM": 490,
            "RNP": 650,
            "LSS": 3000
        },
        "Femmes enceintes": {
            "BNM": 540,
            "RNP": 700,
            "LSS": 3000
        },
        "Femmes allaitantes": {
            "BNM": 1020,
            "RNP": 1300,
            "LSS": 3000
        }
    },
    "Vitamine B1": {
        "Nourrissons de moins de 6 mois": {
	    "proportional to energy": false,
            "AS": 0.2,
        },
        "Nourrissons de 6 mois et plus": {
	    "proportional to energy": true,
            "BNM": 0.072,
            "RNP": 0.1,
        },
        "Enfants de 1 à 3 ans": {
	    "proportional to energy": true,
            "BNM": 0.072,
            "RNP": 0.1,
        },
        "Enfants de 4 à 10 ans": {
	    "proportional to energy": true,
            "BNM": 0.072,
            "RNP": 0.1,
        },
        "Adolescents de 11 à 17 ans": {
	    "proportional to energy": true,
            "BNM": 0.072,
            "RNP": 0.1,
        },
        "Hommes et femmes de 18 ans et plus": {
	    "proportional to energy": true,
            "BNM": 0.072,
            "RNP": 0.1,
        },
        "Femmes enceintes ou allaitantes": {
	    "proportional to energy": true,
            "BNM": 0.072,
            "RNP": 0.1,
        }
    },
    "Vitamine B2": {
        "Nourrissons de moins de 6 mois": {
            "AS": 0.3
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 0.4
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 0.5,
            "RNP": 0.6
        },
        "Enfants de 4 à 6 ans": {
            "BNM": 0.6,
            "RNP": 0.7,
        },
        "Enfants de 7 à 10 ans": {
            "BNM": 0.8,
            "RNP": 1.0,
        },
        "Adolescents de 11 à 14 ans": {
            "BNM": 1.1,
            "RNP": 1.4,
        },
        "Adolescents de 15 à 17 ans": {
            "BNM": 1.4,
            "RNP": 1.6
        },
        "Hommes et femmes de 18 ans et plus": {
            "BNM": 1.3,
            "RNP": 1.6,
        },
        "Femmes enceintes": {
            "BNM": 1.5,
            "RNP": 1.9
        },
        "Femmes allaitantes": {
            "BNM": 1.7,
            "RNP": 2.0,
        }
    },
    "Vitamine B3": {
        "Nourrissons de moins de 6 mois": {
	    "proportional to energy": true,
            "AS": 2
        },
        "Nourrissons de 6 mois et plus": {
	    "proportional to energy": true,
            "BNM": 1.3,
            "RNP": 1.6
        },
        "Enfants de 1 à 3 ans": {
	    "proportional to energy": true,
            "BNM": 1.3,
            "RNP": 1.6,
            "LSSAcide nicotinique": 2,
            "LSSNicotinamide": 150
        },
        "Enfants de 4 à 6 ans": {
	    "proportional to energy": true,
            "BNM": 1.3,
            "RNP": 1.6,
            "LSSAcide nicotinique": 3,
            "LSSNicotinamide": 220
        },
        "Enfants de 7 à 10 ans": {
	    "proportional to energy": true,
            "BNM": 1.3,
            "RNP": 1.6,
            "LSSAcide nicotinique": 4,
            "LSSNicotinamide": 350
        },
        "Adolescents de 11 à 14 ans": {
	    "proportional to energy": true,
            "BNM": 1.3,
            "RNP": 1.6,
            "LSSAcide nicotinique": 6,
            "LSSNicotinamide": 500
        },
        "Adolescents de 15 à 17 ans": {
	    "proportional to energy": true,
            "BNM": 1.3,
            "RNP": 1.6,
            "LSSAcide nicotinique": 8,
            "LSSNicotinamide": 700
        },
        "Hommes et femmes de 18 ans et plus": {
	    "proportional to energy": true,	    
            "BNM": 1.3,
            "RNP": 1.6,
            "LSSAcide nicotinique": 10,
            "LSSNicotinamide": 900
        },
        "Femmes enceintes ou allaitantes": {
	    "proportional to energy": true,	    
            "BNM": 1.3,
            "RNP": 1.6
        }
    },
    "Vitamine B5": {
        "Nourrissons de moins de 6 mois": {
            "AS": 2
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 3
        },
        "Enfants de 1 à 3 ans": {
            "AS": 4
        },
        "Enfants de 4 à 6 ans": {
            "AS": null
        },
        "Enfants de 7 à 10 ans": {
            "AS": 5
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 6
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 6
        },
        "Adolescentes de 15 à 17 ans": {
            "AS": 5
        },
        "Hommes de 18 ans et plus": {
            "AS": 6
        },
        "Femmes de 18 ans et plus": {
            "AS": 5
        },
        "Femmes enceintes": {
            "AS": 5
        },
        "Femmes allaitantes": {
            "AS": 7
        }
    },
    "Vitamine B6": {
        "Nourrissons de moins de 6 mois": {
            "AS": 0.1
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 0.3
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 0.5,
            "RNP": 0.6,
            "LSS": 5
        },
        "Enfants de 4 à 6 ans": {
            "BNM": 0.6,
            "RNP": 0.7,
            "LSS": 7
        },
        "Enfants de 7 à 10 ans": {
            "BNM": 0.9,
            "RNP": 1.0,
            "LSS": 10
        },
        "Adolescents de 11 à 14 ans": {
            "BNM": 1.2,
            "RNP": 1.4,
            "LSS": 15
        },
        "Adolescents de 15 à 17 ans": {
            "BNM": 1.5,
            "RNP": 1.7,
            "LSS": 20
        },
        "Adolescentes de 15 à 17 ans": {
            "BNM": 1.3,
            "RNP": 1.6,
            "LSS": 20
        },
        "Hommes de 18 ans et plus": {
            "BNM": 1.5,
            "RNP": 1.7,
            "LSS": 25
        },
        "Femmes de 18 ans et plus": {
            "BNM": 1.3,
            "RNP": 1.6,
            "LSS": 25
        },
        "Femmes enceintes": {
            "BNM": 1.5,
            "RNP": 1.8,
            "LSS": 25
        },
        "Femmes allaitantes": {
            "BNM": 1.4,
            "RNP": 1.7,
            "LSS": 25
        }
    },
    "Vitamine B8": {
        "Nourrissons de moins de 6 mois": {
            "AS": 4
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 6
        },
        "Enfants de 1 à 3 ans": {
            "AS": 20
        },
        "Enfants de 4 à 6 ans": {
            "AS": 25
        },
        "Enfants de 7 à 10 ans": {
            "AS": 25
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 35
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 35
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 40
        },
        "Femmes enceintes": {
            "AS": 40
        },
        "Femmes allaitantes": {
            "AS": 45
        }
    },
    "Vitamine B9": {
        "Nourrissons de moins de 6 mois": {
            "AS": 65
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 80
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 90,
            "RNP": 120,
            "LSS acide folique": 200
        },
        "Enfants de 4 à 6 ans": {
            "BNM": 110,
            "RNP": 140,
            "LSS acide folique": 300
        },
        "Enfants de 7 à 10 ans": {
            "BNM": 160,
            "RNP": 200,
            "LSS acide folique": 400
        },
        "Adolescents de 11 à 14 ans": {
            "BNM": 210,
            "RNP": 270,
            "LSS acide folique": 600
        },
        "Adolescents de 15 à 17 ans": {
            "BNM": 250,
            "RNP": 330,
            "LSS acide folique": 800
        },
        "Hommes et femmes de 18 ans et plus": {
            "BNM": 250,
            "RNP": 330,
            "LSS acide folique": 1000
        },
        "Femmes susceptibles de devenir enceintes et femmes enceintes": {
            "AS": null,
            "LSS acide folique": 1000
        },
        "Femmes allaitantes": {
            "BNM": 380,
            "RNP": 500,
            "LSS acide folique": 1000
        }
    },
    "Vitamine B12": {
        "Nourrissons de moins de 6 mois": {
            "AS": 0.4
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 1.5
        },
        "Enfants de 1 à 3 ans": {
            "AS": 1.5
        },
        "Enfants de 4 à 10 ans": {
            "AS": 1.5
        },
        "Adolescents de 11 à 17 ans": {
            "AS": 2.5
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 4
        },
        "Femmes enceintes": {
            "AS": 4.5
        },
        "Femmes allaitantes": {
            "AS": 5
        }
    },
    "Vitamine C": {
        "Nourrissons de moins de 6 mois": {
            "AS": 20
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 20
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 15,
            "RNP": 20
        },
        "Enfants de 4 à 6 ans": {
            "BNM": 25,
            "RNP": 30
        },
        "Enfants de 7 à 10 ans": {
            "BNM": 40,
            "RNP": 45
        },
        "Adolescents de 11 à 14 ans": {
            "BNM": 60,
            "RNP": 70
        },
        "Adolescents de 15 à 17 ans": {
            "BNM": 85,
            "RNP": 100
        },
        "Hommes et femmes de 18 ans et plus": {
            "BNM": 90,
            "RNP": 110
        },
        "Femmes enceintes": {
            "BNM": 100,
            "RNP": 120
        },
        "Femmes allaitantes": {
            "BNM": 140,
            "RNP": 170
        }
    },
    "Vitamine D": {
        "Nourrissons de moins de 6 mois": {
            "AS": 10,
            "LSS": 25
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 10,
            "LSS": 25
        },
        "Enfants de 1 à 3 ans": {
            "AS": 15,
            "LSS": 50
        },
        "Enfants de 4 à 10 ans": {
            "AS": 15,
            "LSS": 50
        },
        "Adolescents de 11 à 17 ans": {
            "AS": 15,
            "LSS": 100
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 15,
            "LSS": 100
        },
        "Femmes enceintes ou allaitantes": {
            "AS": 15,
            "LSS": 100
        }
    },
    "Vitamine E": {
        "Nourrissons de moins de 6 mois": {
            "AS": 4
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 5
        },
        "Enfants de 1 à 3 ans": {
            "AS": 7
        },
        "Enfants de 4 à 6 ans": {
            "AS": 7
        },
        "Enfants de 7 à 10 ans": {
            "AS": 9
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 10
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 10
        },
        "Adolescentes de 15 à 17 ans": {
            "AS": 8
        },
        "Hommes de 18 ans et plus": {
            "AS": 10
        },
        "Femmes de 18 ans et plus": {
            "AS": 9
        },
        "Femmes enceintes": {
            "AS": 9
        },
        "Femmes allaitantes": {
            "AS": 9
        }
    },
    "Vitamine K2": { // modified carefull
        "Nourrissons de moins de 6 mois": {
            "AS": 5
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 10
        },
        "Enfants de 1 à 3 ans": {
            "AS": 29
        },
        "Enfants de 4 à 6 ans": {
            "AS": 42
        },
        "Enfants de 7 à 10 ans": {
            "AS": 45
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 45
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 45
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 79
        },
        "Femmes enceintes": {
            "AS": 79
        },
        "Femmes allaitantes": {
            "AS": 79
        }
    },
    "Vitamine K1": { // modified careful
        "Nourrissons de moins de 6 mois": {
            "AS": 5
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 10
        },
        "Enfants de 1 à 3 ans": {
            "AS": 29
        },
        "Enfants de 4 à 6 ans": {
            "AS": 42
        },
        "Enfants de 7 à 10 ans": {
            "AS": 45
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 45
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 45
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 79
        },
        "Femmes enceintes": {
            "AS": 79
        },
        "Femmes allaitantes": {
            "AS": 79
        }
    },

    "Choline": {
        "Nourrissons de moins de 6 mois": {
            "AS": 130
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 160
        },
        "Enfants de 1 à 3 ans": {
            "AS": 140
        },
        "Enfants de 4 à 6 ans": {
            "AS": 170
        },
        "Enfants de 7 à 10 ans": {
            "AS": 250
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 340
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 400
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 400
        },
        "Femmes enceintes": {
            "AS": 480
        },
        "Femmes allaitantes": {
            "AS": 520
        }
    },
    "Calcium": {
        "Nourrissons de moins de 6 mois": {
            "AS": 200
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 280
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 390,
            "RNP": 450
        },
        "Enfants de 4 à 10 ans": {
            "BNM": 680,
            "RNP": 800
        },
        "Adolescents de 11 à 17 ans": {
            "BNM": 960,
            "RNP": 1150
        },
        "Hommes et femmes de 18 à 24 ans": {
            "BNM": 860,
            "RNP": 1000,
            "LSS": 2500
        },
        "Hommes et femmes de 25 ans et plus": {
            "BNM": 750,
            "RNP": 950,
            "LSS": 2500
        },
        "Femmes enceintes ou allaitantes": {
            "BNM": 750,
            "RNP": 950,
            "LSS": 2500
        }
    },
    "Chlore": {
        "Nourrissons de moins de 6 mois": {
            "AS": 170
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 570
        },
        "Enfants de 1 à 3 ans": {
            "AS": 1200
        },
        "Enfants de 4 à 8 ans": {
            "AS": 1500
        },
        "Enfants de 9 à 13 ans": {
            "AS": 1900
        },
        "Adolescents de 14 à 17 ans": {
            "AS": 2300
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 2300
        },
        "Femmes enceintes": {
            "AS": 2300
        },
        "Femmes allaitantes": {
            "AS": 2300
        }
    },
    "Cuivre": {
        "Nourrissons de moins de 6 mois": {
            "AS": 0.3
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 0.5
        },
        "Enfants de 1 à 3 ans": {
            "AS": 0.8,
            "LSS": 1
        },
        "Enfants de 4 à 6 ans": {
            "AS": 1.0,
            "LSS": 2
        },
        "Enfants de 7 à 10 ans": {
            "AS": 1.2,
            "LSS": 3
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 1.3,
            "LSS": 4
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 1.5,
            "LSS": 4
        },
        "Adolescentes de 15 à 17 ans": {
            "AS": 1.1,
            "LSS": 4
        },
        "Hommes de 18 ans et plus": {
            "AS": 1.9,
            "LSS": 5
        },
        "Femmes de 18 ans et plus": {
            "AS": 1.5,
            "LSS": 5
        },
        "Femmes enceintes ou allaitantes": {
            "AS": 1.7
        }
    },
    "Fer": {
        "Nourrissons de moins de 6 mois": {
            "AS": null
        },
        "Nourrissons de 6 mois et plus": {
            "BNM": 8,
            "RNP": 11
        },
        "Enfants de 1 à 2 ans": {
            "BNM": 4,
            "RNP": 5
        },
        "Enfants de 3 à 6 ans": {
            "BNM": 3,
            "RNP": 4
        },
        "Enfants de 7 à 11 ans": {
            "BNM": 5,
            "RNP": 6
        },
        "Adolescents de 12 à 17 ans": {
            "BNM": 8,
            "RNP": 11
        },
        "Adolescentes de 12 à 17 ans non menstruées ou dont les pertes menstruelles sont faibles à modérées": {
            "BNM": 7,
            "RNP": 11
        },
        "Adolescentes de 12 à 17 ans dont les pertes menstruelles sont élevées": {
            "BNM": 7,
            "RNP": 13
        },
        "Hommes de 18 ans et plus": {
            "BNM": 6,
            "RNP": 11
        },
        "Femmes de 18 ans et plus dont les pertes menstruelles sont faibles à modérées": {
            "BNM": 7,
            "RNP": 11
        },
        "Femmes de 18 ans et plus dont les pertes menstruelles sont élevées": {
            "BNM": 7,
            "RNP": 16
        },
        "Femmes enceintes": {
            "BNM": 7,
            "RNP": 16
        },
        "Femmes allaitantes": {
            "BNM": 7,
            "RNP": 16
        },
        "Femmes ménopausées": {
            "BNM": 6,
            "RNP": 11
        }
    },
    "Fluor": {
        "Nourrissons de moins de 6 mois": {
            "AS": null,
            "Groupes de population": null
        },
        "Nourrissons de 6 mois et plus": {
            "AS": null,
            "Groupes de population": null
        },
        "Enfants de 1 à 3 ans": {
            "AS": null,
            "Groupes de population": null,
            "LSS": null
        },
        "Garçons de 4 à 6 ansFilles de 4 à 6 ans": {
            "AS": null,
            "Groupes de population": null,
            "LSS": null
        },
        "Garçons de 7 à 10 ansFilles de 7 à 10 ans": {
            "AS": null,
            "Groupes de population": null,
            "LSS": 55
        },
        "Adolescents de 11 à 14 ansAdolescentes de 11 à 14 ans": {
            "AS": null
        },
        "Adolescents de 15 à 17 ansAdolescentes de 15 à 17 ans": {
            "AS": null,
            "Groupes de population": null,
            "LSS": 77
        },
        "Hommes de 18 ans et plusFemmes de 18 ans et plus": {
            "AS": null,
            "Groupes de population": null,
            "LSS": 77
        },
        "Femmes enceintes ou allaitantes": {
            "AS": null,
            "Groupes de population": null,
            "LSS": 7
        }
    },
    "Iode": {
        "Nourrissons de moins de 6 mois": {
            "AS": 90
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 70
        },
        "Enfants de 1 à 3 ans": {
            "AS": 90,
            "LSS": 200
        },
        "Enfants de 4 à 6 ans": {
            "AS": 90,
            "LSS": 250
        },
        "Enfants de 7 à 10 ans": {
            "AS": 90,
            "LSS": 300
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 120,
            "LSS": 450
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 130,
            "LSS": 500
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 150,
            "LSS": 600
        },
        "Femmes enceintes ou allaitantes": {
            "AS": 200,
            "LSS": 600
        }
    },
    "Magnésium": {
        "Nourrissons de moins de 6 mois": {
            "AS": 25
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 80
        },
        "Enfants de 1 à 3 ans": {
            "AS": 180
        },
        "Enfants de 4 à 6 ans": {
            "AS": 210,
            // "LSS": 250
        },
        "Enfants de 7 à 10 ans": {
            "AS": 240,
            // "LSS": 250
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 265,
            // "LSS": 250
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 295,
            // "LSS": 250
        },
        "Adolescentes de 15 à 17 ans": {
            "AS": 225,
            // "LSS": 250
        },
        "Hommes de 18 ans et plus": {
            "AS": 380,
            // "LSS": 250
        },
        "Femmes de 18 ans et plus": {
            "AS": 300,
            // "LSS": 250
        },
        "Femmes enceintes ou allaitantes": {
            "AS": 300,
            // "LSS": 250
        }
    },
    "Molybdène": {
        "Nourrissons de moins de 6 mois": {
            "AS": 2
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 30
        },
        "Enfants de 1 à 3 ans": {
            "AS": 35,
            "LSS": 100
        },
        "Enfants de 4 à 6 ans": {
            "AS": 65,
            "LSS": 200
        },
        "Enfants de 7 à 10 ans": {
            "AS": 75,
            "LSS": 250
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 80,
            "LSS": 400
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 80,
            "LSS": 500
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 95,
            "LSS": 600
        },
        "Femmes enceintes ou allaitantes": {
            "AS": 95,
            "LSS": 600
        }
    },
    "Phosphore": {
        "Nourrissons de moins de 6 mois": {
            "AS": 100
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 160
        },
        "Enfants de 1 à 3 ans": {
            "AS": 250
        },
        "Enfants de 4 à 6 ans": {
            "AS": 440
        },
        "Enfants de 7 à 10 ans": {
            "AS": 440
        },
        "Adolescents de 11 à 17 ansAdolescentes de 11 à 17 ans": {
            "AS": 640
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 550
        },
        "Femmes enceintes ou allaitantes": {
            "AS": 550
        }
    },
    "Potassium": {
        "Nourrissons de moins de 6 mois": {
            "AS": 400
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 750
        },
        "Enfants de 1 à 3 ans": {
            "AS": 800
        },
        "Enfants de 4 à 6 ans": {
            "AS": 1100
        },
        "Enfants de 7 à 10 ans": {
            "AS": 1800
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 2700
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 3500
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 3500
        },
        "Femmes enceintes": {
            "AS": 3500
        },
        "Femmes allaitantes": {
            "AS": 4000
        }
    },
    "Sélénium": {
        "Nourrissons de moins de 6 mois": {
            "AS": 12.5
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 15
        },
        "Enfants de 1 à 3 ans": {
            "AS": 15,
            "LSS": 60
        },
        "Enfants de 4 à 6 ans": {
            "AS": 20,
            "LSS": 90
        },
        "Enfants de 7 à 10 ans": {
            "AS": 35,
            "LSS": 130
        },
        "Adolescents de 11 à 14 ans": {
            "AS": 55,
            "LSS": 200
        },
        "Adolescents de 15 à 17 ans": {
            "AS": 70,
            "LSS": 250
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 70,
            "LSS": 300
        },
        "Femmes enceintes": {
            "AS": 70,
            "LSS": 300
        },
        "Femmes allaitantes": {
            "AS": 85,
            "LSS": 300
        }
    },
    "Sodium": {
        "Nourrissons de moins de 6 mois": {
            "AS": 110
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 370
        },
        "Enfants de 1 à 3 ans": {
            "AS": 800,
            "LSS": 1200
        },
        "Enfants de 4 à 8 ans": {
            "AS": 1000,
            "LSS": 1500
        },
        "Enfants de 9 à 13 ans": {
            "AS": 1200,
            "LSS": 1800
        },
        "Adolescents de 14 à 17 ans": {
            "AS": 1500,
            "LSS": 2300
        },
        "Hommes et femmes de 18 ans et plus": {
            "AS": 1500,
            "LSS": 2300
        },
        "Femmes enceintes": {
            "AS": 1500,
            "LSS": 2300
        },
        "Femmes allaitantes": {
            "AS": 1500,
            "LSS": 2300
        }
    },
    "Zinc": {
        "300": {
            "BNM": null
        },
        "600": {
            "BNM": null
        },
        "900": {
            "Niveaux d’apports en phytates (mg/j)": null,
            "BNM": 11
        },
        "Nourrissons de moins de 6 mois": {
            "AS": 2
        },
        "Nourrissons de 6 mois et plus": {
            "AS": 2.9
        },
        "Enfants de 1 à 3 ans": {
            "BNM": 3.6,
            "RNP": 4.3,
            "LSS": 7
        },
        "Enfants de 4 à 6 ans": {
            "BNM": 4.6,
            "RNP": 5.5,
            "LSS": 10
        },
        "Enfants de 7 à 10 ans": {
            "BNM": 6.2,
            "RNP": 7.4,
            "LSS": 13
        },
        "Adolescents de 11 à 14 ans": {
            "BNM": 8.8,
            "RNP": 10.7,
            "LSS": 18
        },
        "Adolescents de 15 à 17 ans": {
            "BNM": 11.8,
            "RNP": 14.2,
            "LSS": 22
        },
        "Adolescentes de 15 à 17 ans": {
            "BNM": 9.9,
            "RNP": 11.9,
            "LSS": 22
        },
        "Hommes de 18 ans et plus": {
            "Niveaux d’apports en phytates (mg/j)": 300,
            "BNM": 7.5,
            "RNP": 9.4,
            "LSS": 25
        },
        "Femmes de 18 ans et plus": {
            "Niveaux d’apports en phytates (mg/j)": 300,
            "BNM": 6.2,
            "RNP": 7.5,
            "LSS": 25
        },
        "Femmes enceintes": {
            "Niveaux d’apports en phytates (mg/j)": 300,
            "RNP": 9.1,
            "LSS": 25
        },
        "Femmes allaitantes": {
            "Niveaux d’apports en phytates (mg/j)": 300,
            "RNP": 10.4,
            "LSS": 25
        }
    },

    "AG 20:5 5c,8c,11c,14c,17c (n-3) EPA": {
        "Nourrissons de moins de 6 mois": {
            "AS": 0.100
        },
        "Nourrissons de 6 mois et plus": {
	    "AS": 0.100
        },
	"Enfants de 1 à 2 ans": {
	    "AS": 0.100,
        },
        "Enfants de 2 à 3 ans": {
	    "AS": 0.250
        },
        "Enfants de 4 à 6 ans": {
	    "AS": 0.250
	},
        "Enfants de 7 à 10 ans": {
	    "AS": 0.250
	},
        "Adolescents de 11 à 14 ans": {
	    "AS": 0.250
	},
        "Adolescents de 15 à 17 ans": {
	    "AS": 0.250
	},
        "Adolescentes de 15 à 17 ans": {
	    "AS": 0.250
	},
        "Hommes de 18 ans et plus": {
	    "AS": 0.250
	},
        "Femmes de 18 ans et plus": {
	    "AS": 0.250
	},
        "Femmes enceintes": {
	    "AS": 0.250 + 0.200
	},
        "Femmes allaitantes": {
	    "AS": 0.250 + 0.200
	}
    },

    "AG 22:6 4c,7c,10c,13c,16c,19c (n-3) DHA": {
	"Nourrissons de moins de 6 mois": {
            "AS": 0.100
        },
        "Nourrissons de 6 mois et plus": {
	    "AS": 0.100
        },
	"Enfants de 1 à 2 ans": {
	    "AS": 0.100,
        },
        "Enfants de 2 à 3 ans": {
	    "AS": 0.250
        },

        "Enfants de 4 à 6 ans": {
	    "AS": 0.250
	},
        "Enfants de 7 à 10 ans": {
	    "AS": 0.250
	},
        "Adolescents de 11 à 14 ans": {
	    "AS": 0.250
	},
        "Adolescents de 15 à 17 ans": {
	    "AS": 0.250
	},
        "Adolescentes de 15 à 17 ans": {
	    "AS": 0.250
	},
        "Hommes de 18 ans et plus": {
	    "AS": 0.250
	},
        "Femmes de 18 ans et plus": {
	    "AS": 0.250
	},
        "Femmes enceintes": {
	    "AS": 0.250 + 0.200
	},
        "Femmes allaitantes": {
	    "AS": 0.250 + 0.200
	}
    },

    "AG 18:2 9c,12c (n-6), linoléique": {
	"Nourrissons de moins de 6 mois": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
            "AS": 0.04, //%energy
        },
        "Nourrissons de 6 mois et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
	"Enfants de 1 à 2 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
        },
        "Enfants de 2 à 3 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Enfants de 4 à 6 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Enfants de 7 à 10 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Adolescents de 11 à 14 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Adolescents de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Adolescentes de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Hommes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Femmes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Femmes enceintes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	},
        "Femmes allaitantes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.04, //%energy
	}
    },

    "AG 18:3 c9,c12,c15 (n-3), alpha-linolénique": {
	"Nourrissons de moins de 6 mois": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
            "AS": 0.005, //%energy
        },
        "Nourrissons de 6 mois et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
	"Enfants de 1 à 2 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
        },
        "Enfants de 2 à 3 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Enfants de 4 à 6 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Enfants de 7 à 10 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Adolescents de 11 à 14 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Adolescents de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Adolescentes de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Hommes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Femmes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Femmes enceintes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	},
        "Femmes allaitantes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.005, //%energy
	}
    },

    "Glucides": {
	"Nourrissons de moins de 6 mois": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
            "AS": 0.45, //%energy
        },
        "Nourrissons de 6 mois et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
	"Enfants de 1 à 2 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
        },
        "Enfants de 2 à 3 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Enfants de 4 à 6 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Enfants de 7 à 10 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Adolescents de 11 à 14 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Adolescents de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Adolescentes de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Hommes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Femmes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Femmes enceintes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	},
        "Femmes allaitantes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "16.736", //kjoules
	    "AS": 0.45, //%energy
	}
    },

    "Lipides": {
	"Nourrissons de moins de 6 mois": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
            "AS": 0.35, //%energy
        },
        "Nourrissons de 6 mois et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
	"Enfants de 1 à 2 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
        },
        "Enfants de 2 à 3 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Enfants de 4 à 6 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Enfants de 7 à 10 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Adolescents de 11 à 14 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Adolescents de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Adolescentes de 15 à 17 ans": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Hommes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Femmes de 18 ans et plus": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Femmes enceintes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	},
        "Femmes allaitantes": {
	    "proportional to total energy": true,
	    "unit_to_energy_conversion_rate": "37.656", //kjoules
	    "AS": 0.35, //%energy
	}
    },

    "Manganèse": {
        "Nourrissons de 6 mois et plus": {
	    "AS": 0.5, 
	},
	"Enfants de 1 à 3 ans": {
	    "AS": 0.5, 
        },
        "Enfants de 4 à 6 ans": {
	    "AS": 1.0, 
	},
        "Enfants de 7 à 10 ans": {
	    "AS": 1.5, 
	},
        "Adolescents de 11 à 14 ans": {
	    "AS": 2, 
	},
        "Adolescents de 15 à 17 ans": {
	    "AS": 3, 
	},
        "Adolescentes de 15 à 17 ans": {
	    "AS": 3, 
	},
        "Hommes de 18 ans et plus": {
	    "AS": 3, 
	},
        "Femmes de 18 ans et plus": {
	    "AS": 3, 
	},
        "Femmes enceintes": {
	    "AS": 3, 
	},
        "Femmes allaitantes": {
	    "AS": 3, 
	}
    },

    "Protéines, N x 6.25" : {
        "Nourrissons de 6 mois et plus": {
	    "proportional to body weight": true,
	    "BNM": 1.12,
	    "RNP": 1.31,
	},
	"Enfants de 1 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.95,
	    "RNP": 1.14,
        },
        "Enfants de 2 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.79,
	    "RNP": 0.97,
	},
	"Enfants de 3 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.73,
	    "RNP": 0.9,
	},
	"Enfants de 4 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.69,
	    "RNP": 0.86,
	},
        "Enfants de 5 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.69,
	    "RNP": 0.85,
	},
        "Enfants de 6 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.72,
	    "RNP": 0.89,
	},
        "Enfants de 7 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.74,
	    "RNP": 0.91,
	},
        "Enfants de 8 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.75,
	    "RNP": 0.92,
	},
        "Enfants de 9 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.75,
	    "RNP": 0.92,
	},
        "Enfants de 10 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.75,
	    "RNP": 0.91,
	},
        "Adolescents de 11 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.75,
	    "RNP": 0.91,
	},
	"Adolescentes de 11 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.73,
	    "RNP": 0.9,
	},
	"Adolescents de 12 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.74,
	    "RNP": 0.9,
	},
	"Adolescentes de 12 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.72,
	    "RNP": 0.89,
	},
	"Adolescents de 13 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.73,
	    "RNP": 0.9,
	},
	"Adolescentes de 13 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.71,
	    "RNP": 0.88,
	},
	"Adolescents de 14 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.72,
	    "RNP": 0.89,
	},
	"Adolescentes de 14 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.7,
	    "RNP": 0.87,
	},
	"Adolescents de 15 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.72,
	    "RNP": 0.88,
	},
	"Adolescentes de 15 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.69,
	    "RNP": 0.85,
	},
	"Adolescents de 16 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.71,
	    "RNP": 0.87,
	},
	"Adolescentes de 16 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.68,
	    "RNP": 0.84,
	},

	"Adolescents de 17 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.7,
	    "RNP": 0.86,
	},
	"Adolescentes de 17 ans": {
	    "proportional to body weight": true,
	    "BNM": 0.67,
	    "RNP": 0.83,
	},
        "Hommes de 18 ans et plus": {
	    "proportional to body weight": true,
	    "BNM": 0.66,
	    "RNP": 0.83,
	},
        "Femmes de 18 ans et plus": {
	    "proportional to body weight": true,
	    "BNM": 0.66,
	    "RNP": 0.83,
	},
        // "Femmes enceintes": {
	//     "proportional to body weight": true,
	//     "BNM": ,
	//     "RNP": ,
	// },
        // "Femmes allaitantes": {
	//     "proportional to body weight": true,
	//     "BNM": ,
	//     "RNP": ,
	// } //TODO: We should implement special code for pregnant womens' protein requirements
    },

};


const synonyms = {
    "Hommes de 18 ans et plus": [ "Hommes et femmes de 18 ans et plus" ]
    
};


for (let nutrient in references) {
    for (let demographic in references[nutrient]) {
	Object.defineProperty(references[nutrient][demographic], "lower_reference", { get() {
	    if (this.RNP != null)
		return this.RNP
	    if (this.AS != null)
		return this.AS
	    else
		return this.BNM
	} })

	Object.defineProperty(references[nutrient][demographic], "upper_reference", { get() {
	    return this.LSS
	}})
	
	Object.defineProperty(references[nutrient][demographic], "proportional_to_energy", { get() {
	    if (this["proportional to energy"] === undefined) {
		return false
	    } else {
		return this["proportional to energy"]
	    }
	}})

	Object.defineProperty(references[nutrient][demographic], "proportional_to_total_energy", { get() {
	    if (this["proportional to total energy"] === undefined) {
		return false
	    } else {
		return this["proportional to total energy"]
	    }
	}})

	Object.defineProperty(references[nutrient][demographic], "proportional_to_body_weight", { get() {
	    if (this["proportional to body weight"] === undefined) {
		return false
	    } else {
		return this["proportional to body weight"]
	    }
	}})

    }

    references[nutrient].demographics = function(demographics) {
	for (let demo of demographics) {
	    if (references[nutrient][demo]) {
		return references[nutrient][demo]
	    }
	}
	return undefined
    }
    
}


    // for (let demographic in synonyms) {
    // 	if (references[nutrient][demographic] == undefined) {
    // 	    let trailer = ""

    // 	    for (let synonym of synonyms[demographic]) {
    // 		trailer += `references[${nutrient}][${synonym}] and `
    // 	    }

	    
    // 	    console.log(`references[${nutrient}][${demographic}] is synonymous for ${trailer}`)

	    
    // 	    Object.defineProperty(references[nutrient], demographic, { get() {
    // 		console.log(nutrient, "[", demographic ,"] is undefined")
    // 		for (let synonym of synonyms[demographic]) {
    // 		    if (this[synonym] != undefined) {
    // 			console.log(nutrient, "[", demographic ,"] is undefined but it has a defined synonym: ", synonym)
    // 			return this[synonym]
    // 		    }
    // 		}
    // 		return undefined
		
    // 	    }})
    // 	}
    // }


function getDemographics(user_description) {
    const {
	pregnant,
	may_become_pregnant,
	breastfeeding,
	low_to_medium_period,
	heavy_period,
	menopaused,
	sex,
	age
    } = user_description

    // console.log(`Getting demographics for`)
    // console.log(user_description)
    
    let demographics = []
    

    if (age <= 0) {
	// unimplemented
	// 	    "Nourrissons de moins de 6 mois",
	// "Nourrissons de 6 mois et plus",
	demographics.push("Nourrissons de 6 mois et plus") // TODO: This be a fix for now
    }

    if (age >= 1 && age <= 11) {
	if (age == 1) {
	    demographics.push("Enfants de 1 ans")
	}
	if (age == 2) {
	    demographics.push("Enfants de 2 ans")
	}
	if (age == 3) {
	    demographics.push("Enfants de 3 ans")
	}
	if (age == 4) {
	    demographics.push("Enfants de 4 ans")
	}
	if (age == 5) {
	    demographics.push("Enfants de 5 ans")
	}
	if (age == 6) {
	    demographics.push("Enfants de 6 ans")
	}
	if (age == 7) {
	    demographics.push("Enfants de 7 ans")
	}
	if (age == 8) {
	    demographics.push("Enfants de 8 ans")
	}
	if (age == 9) {
	    demographics.push("Enfants de 9 ans")
	}
	if (age == 10) {
	    demographics.push("Enfants de 10 ans")
	}
	if (age >= 1 && age <= 2) {
	    demographics.push("Enfants de 1 à 2 ans")
	}
	if (age >= 1 && age <= 3) {
	    demographics.push("Enfants de 1 à 3 ans")
	}
	if (age >= 2 && age <= 3) {
	    demographics.push("Enfants de 2 à 3 ans")
	}
	if (age >= 4 && age <= 6) {
	    demographics.push("Enfants de 4 à 6 ans")
	}
	if (age >= 7 && age <= 10) {
	    demographics.push("Enfants de 7 à 10 ans")
	}
	if (age >= 4 && age <= 10) {
	    demographics.push("Enfants de 4 à 10 ans")
	}
	if (age >= 4 && age <= 8) {
	    demographics.push("Enfants de 4 à 8 ans")
	}
	if (age >= 9 && age <= 13) {
	    demographics.push("Enfants de 9 à 13 ans")
	}
	if (age >= 1 && age <= 2) {
	    demographics.push("Enfants de 1 à 2 ans")
	}
	if (age >= 3 && age <= 6) {
	    demographics.push("Enfants de 3 à 6 ans")
	}
	if (age >= 7 && age <= 11) {
	    demographics.push("Enfants de 7 à 11 ans")
	}
	if (age >= 4 && age <= 6) {
	    demographics.push("Garçons de 4 à 6 ansFilles de 4 à 6 ans")
	}
	if (age >= 7 && age <= 10) {
	    demographics.push("Garçons de 7 à 10 ansFilles de 7 à 10 ans")
	}
    }

    if (age >= 9 && age <= 13) {
	demographics.push("Enfants de 9 à 13 ans")
    }

    if (age >= 11 && age <= 17) {
	if (sex === 'male') {
	    if (age == 11) {
		demographics.push("Adolescents de 11 ans")
	    }
	    if (age == 12) {
		demographics.push("Adolescents de 12 ans")
	    }
	    if (age == 13) {
		demographics.push("Adolescents de 13 ans")
	    }
	    if (age == 14) {
		demographics.push("Adolescents de 14 ans")
	    }
	    if (age == 15) {
		demographics.push("Adolescents de 15 ans")
	    }
	    if (age == 16) {
		demographics.push("Adolescents de 16 ans")
	    }
	    if (age == 17) {
		demographics.push("Adolescents de 17 ans")
	    }
	    if (age == 18) {
		demographics.push("Adolescents de 18 ans")
	    }
		
	    
	    if (age >= 11 && age <= 14) {
		demographics.push("Adolescents de 11 à 14 ans",)
	    }
	    if (age >= 15 && age <= 17) {
		demographics.push("Adolescents de 15 à 17 ans",)
	    }
	    if (age >= 11 && age <= 17) {
		demographics.push("Adolescents de 11 à 17 ans",)
	    }
	    if (age >= 14 && age <= 17) {
		demographics.push("Adolescents de 14 à 17 ans",)
	    }
	    if (age >= 12 && age <= 17) {
		demographics.push("Adolescents de 12 à 17 ans",)
	    }
	    if (age >= 11 && age <= 17) {
		demographics.push("Adolescents de 11 à 17 ansAdolescentes de 11 à 17 ans",)
	    }
	    if (age >= 11 && age <= 14) {
		demographics.push("Adolescents de 11 à 14 ansAdolescentes de 11 à 14 ans",)
	    }
	    if (age >= 15 && age <= 17) {
		demographics.push("Adolescents de 15 à 17 ansAdolescentes de 15 à 17 ans",)
	    }
	    
	} else {
	    if (age == 11) {
		demographics.push("Adolescentes de 11 ans")
	    }
	    if (age == 12) {
		demographics.push("Adolescentes de 12 ans")
	    }
	    if (age == 13) {
		demographics.push("Adolescentes de 13 ans")
	    }
	    if (age == 14) {
		demographics.push("Adolescentes de 14 ans")
	    }
	    if (age == 15) {
		demographics.push("Adolescentes de 15 ans")
	    }
	    if (age == 16) {
		demographics.push("Adolescentes de 16 ans")
	    }
	    if (age == 17) {
		demographics.push("Adolescentes de 17 ans")
	    }
	    if (age == 18) {
		demographics.push("Adolescentes de 18 ans")
	    }

	    
	    if (age >= 11 && age <= 17) {
		demographics.push("Adolescents de 11 à 17 ansAdolescentes de 11 à 17 ans",)
	    }
	    
	    if (age >= 11 && age <= 14) {
		demographics.push("Adolescents de 11 à 14 ansAdolescentes de 11 à 14 ans",)
	    }
	    
	    if (age >= 15 && age <= 17) {
		demographics.push("Adolescents de 15 à 17 ansAdolescentes de 15 à 17 ans",)
	    }
	    
	    if (age >= 15 && age <= 17) {
		demographics.push("Adolescentes de 15 à 17 ans",)
	    }

	    if (age >= 12 && age <= 17) {
		if (low_to_medium_period) {
		    demographics.push("Adolescentes de 12 à 17 ans non menstruées ou dont les pertes menstruelles sont faibles à modérées")
		} else {
		    demographics.push("Adolescentes de 12 à 17 ans dont les pertes menstruelles sont élevées")
		}
	    }

	}	

    }

    if (age >= 18) {
	if (sex === 'male') {

	    if (age >= 18) {
		demographics.push("Hommes de 18 ans et plus")
	    }
	    if (age >= 18 && age <= 24) {
		demographics.push("Hommes et femmes de 18 à 24 ans")
	    }
	    if (age >= 25) {
		demographics.push("Hommes et femmes de 25 ans et plus")
	    }
	    if (age >= 18) {
		demographics.push("Hommes et femmes de 18 ans et plus")
	    }
	    if (age >= 18) {
		demographics.push("Hommes de 18 ans et plusFemmes de 18 ans et plus")
	    }
	} else {
	    if (age >= 18 && age <= 24) {
		demographics.push("Hommes et femmes de 18 à 24 ans")
	    }
	    if (age >= 25) {
		demographics.push("Hommes et femmes de 25 ans et plus")
	    }
	    if (age >= 18) {
		demographics.push("Hommes et femmes de 18 ans et plus")
	    }
	    if (age >= 18) {
		demographics.push("Hommes de 18 ans et plusFemmes de 18 ans et plus")
	    }


	    demographics.push("Femmes de 18 ans et plus")

	    // if (low_to_medium_period) {
	    if (!heavy_period) {
		demographics.push("Femmes de 18 ans et plus dont les pertes menstruelles sont faibles à modérées")
	    }

	    if (heavy_period) {
		demographics.push("Femmes de 18 ans et plus dont les pertes menstruelles sont élevées")
	    }

	    if (pregnant) {
		demographics.push("Femmes enceintes")
	    }

	    if (breastfeeding) {
		demographics.push("Femmes allaitantes")
	    }

	    if (pregnant || breastfeeding) {
		demographics.push("Femmes enceintes ou allaitantes")
	    }

	    if (pregnant || may_become_pregnant) {
		demographics.push("Femmes susceptibles de devenir enceintes et femmes enceintes")
	    }

	    if (menopaused) {
		demographics.push("Femmes ménopausées")
	    }
	}	
    }

    return demographics.reverse() // TODO: Don't really know about that one
}

export { getDemographics, references };


// for (let nutrient in references) {
//     for (let demographic in references[nutrient]) {
// 	console.log(references[nutrient][demographic].lower_reference)
// 	console.log(references[nutrient][demographic].upper_reference)
//     }
