import logo from './logo.svg';
import './App.css';
import './table.css';
import alim_data_file from "./alim.xml";
import const_data_file from "./const_2020_07_07.xml"
import compo_data_file from "./compo_2020_07_07.xml"

import { foodDB } from "./index.js"
import { FoodDB, plainArrayToNutrientArray } from "./foodDB.js"

import React from 'react';
import { useState }  from 'react';
import { references, getDemographics } from './nutritional_references.js'


String.prototype.reverse = function() {
    return this.split("").reverse().join("")
}

class UserDescription extends React.Component {
    
    constructor(props) {
        super(props);
	
        this.state = this.props.demographics
	this.handleChange = this.handleChange.bind(this)
	this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
    }


    handleChange(event) {
	let { name, value } = event.target
	let state = { ...this.state }

	state[name] = value

	if (name === 'sex' && value === 'male') {
	    state.pregnant = false
	    state.may_become_pregnant = false
	    state.breastfeeding = false
	    state.low_to_medium_period = false
	    state.heavy_period = false
	    state.menopaused = false
	}

	this.setState(state)
	// console.log(state)
	
	this.props.submitDemographics(state)
    }

    handleChangeCheckbox(event) {
	let { name, checked } = event.target

	const pregnant = document.getElementById("pregnant")
	const may_become_pregnant = document.getElementById("may_become_pregnant")
	const breastfeeding = document.getElementById("breastfeeding")
	const low_to_medium_period = document.getElementById("low_to_medium_period")
	const heavy_period = document.getElementById("heavy_period")
	const menopaused = document.getElementById("menopaused")


	if (checked && name === "pregnant") {
	    menopaused.checked = false
	    may_become_pregnant.checked = false
	    low_to_medium_period.checked = false
	    heavy_period.checked = false
	}

	
	if (checked && name === "may_become_pregnant") {
	    pregnant.checked = false
	    menopaused.checked = false
	}

	if (checked && name === "menopaused") {
	    pregnant.checked = false
	    may_become_pregnant.checked = false
	    breastfeeding.checked = false
	    heavy_period.checked = false
	    low_to_medium_period.checked = false
	}

	if (checked && name === "breastfeeding") {
	    menopaused.checked = false
	}
	

	if (checked && name === "low_to_medium_period") {
	    heavy_period.checked = false
	    pregnant.checked = false
	}

	if (checked && name === "heavy_period") {
	    low_to_medium_period.checked = false
	    pregnant.checked = false
	}

	

	const state = this.state

	state[name] = checked

	this.setState(state)
	// console.log(state)

	this.props.submitDemographics(state)
    }


    render() {
	const { age,
		weight,
		sex,
		pregnant,
		may_become_pregnant,
		breastfeeding,
		low_to_medium_period,
		heavy_period,
		menopaused } = this.state

	const { handleChange, handleChangeCheckbox } = this

	const submitDemographics = this

	const tableHeaderStyle = {
	"textAlign" : "center"
	}

	
	return <table className="nutrientsTable">
		   <thead>
		       <tr style={tableHeaderStyle}>
			   <th>
			       Utilisateur
			   </th>
			   <th>
			   </th>
			       
		       </tr>
		   </thead>
		   <tbody>
		       <tr key="Age">
			   <td>Age</td>
			   <td className="userInformation"> <input name="age" type="number" min="0" value={age} onChange={handleChange}/> </td>
		       </tr>
		       <tr key="Poids">
			   <td>Poids</td>
			   <td className="userInformation"> <input name="weight" type="number" min="0" value={weight} onChange={handleChange}/> </td>
		       </tr>
		       <tr key="Sexe">
			   <td>Sexe</td>
			   <td className="userInformation">
 			       <select name="sex" onChange={handleChange} defaultValue={sex} className="sexSelector" id="sex-selector">
				   <option value="male"> Homme / Garçon </option>
				   <option value="female"> Femme / Fille </option>
			       </select>
			   </td>
		       </tr>
		       { sex === 'female' ? [
		       <tr key="Enceinte">
			   <td>Enceinte</td>
			   <td className="userInformation"> <input id="pregnant" name="pregnant" type="checkbox" value={pregnant} onChange={handleChangeCheckbox}/> </td>
		       </tr>,
		       <tr key="Susceptible de devenir enceinte">
			   <td>Susceptible de devenir enceinte</td>
			   <td className="userInformation"> <input id="may_become_pregnant" name="may_become_pregnant" type="checkbox" value={may_become_pregnant} onChange={handleChangeCheckbox}/> </td>
		       </tr>,
		       <tr key="Allaitante">
			   <td>Allaitante</td>
			   <td className="userInformation"> <input id="breastfeeding" name="breastfeeding" type="checkbox" value={breastfeeding} onChange={handleChangeCheckbox}/> </td>
		       </tr>,
		       <tr key="Dont les pertes menstruelles sont de faible à modérée">
			   <td>Dont les pertes menstruelles sont de faible à modérée</td>
			   <td className="userInformation"> <input id="low_to_medium_period" name="low_to_medium_period" type="checkbox" value={low_to_medium_period} onChange={handleChangeCheckbox}/> </td>
		       </tr>,
		       <tr key="Dont les pertes menstruelle sont élévée">
			   <td>Dont les pertes menstruelle sont élévée</td>
			   <td className="userInformation"> <input id="heavy_period" name="heavy_period" type="checkbox" value={heavy_period} onChange={handleChangeCheckbox}/> </td>
		       </tr>,
		       <tr key="Menopausée">
			   <td>Menopausée</td>
			   <td className="userInformation"> <input id="menopaused" name="menopaused" type="checkbox" value={menopaused} onChange={handleChangeCheckbox}/> </td>
		       </tr>,
		       ]: []}
		   </tbody>
	       </table>
		   
    }
}


class NutritionTableEntry extends React.Component {
    state = {
	hover : false,
	focused : false,
    }

    constructor(props) {
	super(props);
	
	this.onMouseEnter = this.onMouseEnter.bind(this)
	this.onMouseLeave = this.onMouseLeave.bind(this)
	this.onClick = this.onClick.bind(this)
    }

    onMouseEnter() {
	this.setState({
	    hover : true
	})
    }

    onMouseLeave() {
	this.setState({
	    hover : false
	})
    }
    
    onClick() {
	this.props.select(this.props.name)
    }
    
    render() {

	const { index,
		name,
		unitless_name,
		unit,
		upper_reference_string,
		lower_reference_string,
		ri_percentage_string,
		value,		
		intake_class } = this.props

	return <tr 
		   onMouseEnter={ this.onMouseEnter }
		   onMouseLeave={ this.onMouseLeave }
		   onClick= { this.onClick }
		   className={ this.state.hover ? "active-search-option" : intake_class }>
		   <td> {unitless_name} </td>
		   <td> {`${upper_reference_string} ${value.toFixed(2)} ${unit} ${lower_reference_string} ${ri_percentage_string}`} </td>
	       </tr>
    }
}

var last_intake_classes = {} // Gross fucking hack to get same order when format by deficienses 

function NutrientsTable(props) {
    const natural = [
	"Energie, Règlement UE N° 1169/2011 (kJ/100 g)",
	"Energie, Règlement UE N° 1169/2011 (kcal/100 g)",
	"Energie, N x facteur Jones, avec fibres  (kJ/100 g)",
	"Energie, N x facteur Jones, avec fibres  (kcal/100 g)",
	"Eau (g/100 g)",
	"Cendres (g/100 g)",
	"Sel chlorure de sodium (g/100 g)",
	"Sodium (mg/100 g)",
	"Magnésium (mg/100 g)",
	"Phosphore (mg/100 g)",
	"Chlorure (mg/100 g)",
	"Potassium (mg/100 g)",
	"Calcium (mg/100 g)",
	"Manganèse (mg/100 g)",
	"Fer (mg/100 g)",
	"Cuivre (mg/100 g)",
	"Zinc (mg/100 g)",
	"Sélénium (µg/100 g)",
	"Iode (µg/100 g)",
	"Protéines, N x facteur de Jones (g/100 g)",
	"Protéines, N x 6.25 (g/100 g)",
	"Glucides (g/100 g)",
	"Sucres (g/100 g)",
	"Fructose (g/100 g)",
	"Galactose (g/100 g)",
	"Glucose (g/100 g)",
	"Lactose (g/100 g)",
	"Maltose (g/100 g)",
	"Saccharose (g/100 g)",
	"Amidon (g/100 g)",
	"Polyols totaux (g/100 g)",
	"Fibres alimentaires (g/100 g)",
	"Lipides (g/100 g)",
	"AG saturés (g/100 g)",
	"AG monoinsaturés (g/100 g)",
	"AG polyinsaturés (g/100 g)",
	"AG 4:0, butyrique (g/100 g)",
	"AG 6:0, caproïque (g/100 g)",
	"AG 8:0, caprylique (g/100 g)",
	"AG 10:0, caprique (g/100 g)",
	"AG 12:0, laurique (g/100 g)",
	"AG 14:0, myristique (g/100 g)",
	"AG 16:0, palmitique (g/100 g)",
	"AG 18:0, stéarique (g/100 g)",
	"AG 18:1 9c (n-9), oléique (g/100 g)",
	"AG 18:2 9c,12c (n-6), linoléique (g/100 g)",
	"AG 18:3 c9,c12,c15 (n-3), alpha-linolénique (g/100 g)",
	"AG 20:4 5c,8c,11c,14c (n-6), arachidonique (g/100 g)",
	"AG 20:5 5c,8c,11c,14c,17c (n-3) EPA (g/100 g)",
	"AG 22:6 4c,7c,10c,13c,16c,19c (n-3) DHA (g/100 g)",
	"Vitamine A (µg/100 g)",
	"Beta-Carotène (µg/100 g)",
	"Vitamine D (µg/100 g)",
	"Vitamine E (mg/100 g)",
	"Vitamine K1 (µg/100 g)",
	"Vitamine K2 (µg/100 g)",
	"Vitamine C (mg/100 g)",
	"Vitamine B1 (mg/100 g)",
	"Vitamine B2 (mg/100 g)",
	"Vitamine B3 (mg/100 g)",
	"Vitamine B5 (mg/100 g)",
	"Vitamine B6 (mg/100 g)",
	"Vitamine B12 (µg/100 g)",
	"Vitamine B9 (µg/100 g)",
	"Alcool (g/100 g)",
	"Acides organiques (g/100 g)",
	"Cholestérol (mg/100 g)",
    ]
    
    const preferred = [ "Energie, N x facteur Jones, avec fibres  (kcal/100 g)",
			"Protéines, N x 6.25 (g/100 g)",
			"Glucides (g/100 g)",
			"Lipides (g/100 g)",

			"AG saturés (g/100 g)",
			"AG monoinsaturés (g/100 g)",
			"AG polyinsaturés (g/100 g)",

			"Sucres (g/100 g)",			     
			"Fructose (g/100 g)",
			"Galactose (g/100 g)",
			"Glucose (g/100 g)",
			"Lactose (g/100 g)",
			"Maltose (g/100 g)",
			"Saccharose (g/100 g)",

			"Vitamine A (µg/100 g)",
			"Beta-Carotène (µg/100 g)",
			"Vitamine C (mg/100 g)",
			"Vitamine D (µg/100 g)",
			"Vitamine E (mg/100 g)",
			"Vitamine K1 (µg/100 g)",
			"Vitamine K2 (µg/100 g)",
			"Vitamine B1 (mg/100 g)",
			"Vitamine B2 (mg/100 g)",
			"Vitamine B3 (mg/100 g)",
			"Vitamine B5 (mg/100 g)",
			"Vitamine B6 (mg/100 g)",
			"Vitamine B12 (µg/100 g)",
			"Vitamine B9 (µg/100 g)",
			
			"Sel chlorure de sodium (g/100 g)",
			"Sodium (mg/100 g)",
			"Magnésium (mg/100 g)",
			"Phosphore (mg/100 g)",
			"Chlorure (mg/100 g)",
			"Potassium (mg/100 g)",
			"Calcium (mg/100 g)",
			"Manganèse (mg/100 g)",
			"Fer (mg/100 g)",
			"Cuivre (mg/100 g)",			     
			"Zinc (mg/100 g)",
			"Sélénium (µg/100 g)",
			"Iode (µg/100 g)",

			"AG 18:3 c9,c12,c15 (n-3), alpha-linolénique (g/100 g)",
			"AG 20:5 5c,8c,11c,14c,17c (n-3) EPA (g/100 g)",
			"AG 22:6 4c,7c,10c,13c,16c,19c (n-3) DHA (g/100 g)",

			"AG 18:2 9c,12c (n-6), linoléique (g/100 g)",
			"AG 20:4 5c,8c,11c,14c (n-6), arachidonique (g/100 g)",
			"AG 18:1 9c (n-9), oléique (g/100 g)",
			

			"Amidon (g/100 g)",
			"Polyols totaux (g/100 g)",
			"Fibres alimentaires (g/100 g)",
			
			"AG 4:0, butyrique (g/100 g)",
			"AG 6:0, caproïque (g/100 g)",
			"AG 8:0, caprylique (g/100 g)",
			"AG 10:0, caprique (g/100 g)",
			"AG 12:0, laurique (g/100 g)",
			"AG 14:0, myristique (g/100 g)",
			"AG 16:0, palmitique (g/100 g)",
			"AG 18:0, stéarique (g/100 g)",
			
			"Eau (g/100 g)",
			"Alcool (g/100 g)",
			"Acides organiques (g/100 g)",
			"Cholestérol (mg/100 g)",
			"Energie, Règlement UE N° 1169/2011 (kJ/100 g)",
			"Energie, Règlement UE N° 1169/2011 (kcal/100 g)",
			"Energie, N x facteur Jones, avec fibres  (kJ/100 g)",
			"Protéines, N x facteur de Jones (g/100 g)",
			"Cendres (g/100 g)",
		      ]

    const abridged = [ "Energie, N x facteur Jones, avec fibres  (kcal/100 g)",
		       "Protéines, N x 6.25 (g/100 g)",
		       "Glucides (g/100 g)",
		       "Lipides (g/100 g)",

		       "AG saturés (g/100 g)",
		       "AG monoinsaturés (g/100 g)",
		       "AG polyinsaturés (g/100 g)",

		       "Sucres (g/100 g)",			     
		       
		       "Vitamine A (µg/100 g)",
		       "Vitamine C (mg/100 g)",
		       "Beta-Carotène (µg/100 g)",
		       "Vitamine D (µg/100 g)",
		       "Vitamine E (mg/100 g)",
		       "Vitamine K1 (µg/100 g)",
		       "Vitamine K2 (µg/100 g)",
		       "Vitamine B1 (mg/100 g)",
		       "Vitamine B2 (mg/100 g)",
		       "Vitamine B3 (mg/100 g)",
		       "Vitamine B5 (mg/100 g)",
		       "Vitamine B6 (mg/100 g)",
		       "Vitamine B12 (µg/100 g)",
		       "Vitamine B9 (µg/100 g)",
		       
		       "Sodium (mg/100 g)",
		       "Magnésium (mg/100 g)",
		       "Phosphore (mg/100 g)",
		       "Chlorure (mg/100 g)",
		       "Potassium (mg/100 g)",
		       "Calcium (mg/100 g)",
		       "Manganèse (mg/100 g)",
		       "Fer (mg/100 g)",
		       "Cuivre (mg/100 g)",			     
		       "Zinc (mg/100 g)",
		       "Sélénium (µg/100 g)",
		       "Iode (µg/100 g)",

		       "AG 18:3 c9,c12,c15 (n-3), alpha-linolénique (g/100 g)",
		       "AG 20:5 5c,8c,11c,14c,17c (n-3) EPA (g/100 g)",
		       "AG 22:6 4c,7c,10c,13c,16c,19c (n-3) DHA (g/100 g)",

		       "AG 18:2 9c,12c (n-6), linoléique (g/100 g)",
		       "AG 20:4 5c,8c,11c,14c (n-6), arachidonique (g/100 g)",
		       "AG 18:1 9c (n-9), oléique (g/100 g)",
		     ]

    const pufa = [
	"AG 18:3 c9,c12,c15 (n-3), alpha-linolénique (g/100 g)",
	"AG 20:5 5c,8c,11c,14c,17c (n-3) EPA (g/100 g)",
	"AG 22:6 4c,7c,10c,13c,16c,19c (n-3) DHA (g/100 g)",
	"AG 18:2 9c,12c (n-6), linoléique (g/100 g)",
	"AG 20:4 5c,8c,11c,14c (n-6), arachidonique (g/100 g)",
	"AG 18:1 9c (n-9), oléique (g/100 g)",
    ]

    const sugars = [
	"Glucides (g/100 g)",
	"Sucres (g/100 g)",
	"Fructose (g/100 g)",
	"Galactose (g/100 g)",
	"Glucose (g/100 g)",
	"Lactose (g/100 g)",
	"Maltose (g/100 g)",
	"Saccharose (g/100 g)",
	"Amidon (g/100 g)",
    ]
    
    const macros = [ "Energie, N x facteur Jones, avec fibres  (kcal/100 g)",
		     "Protéines, N x 6.25 (g/100 g)",
		     "Glucides (g/100 g)",
		     "Lipides (g/100 g)" ]


    const [ order, setSelectedOrder ] = useState("Déficiences/Excès")

    
    
    const setSelected = (event) => {
	const value = event.target.value
	
	console.log(`Changing selected format to ${value}`)
	
	setSelectedOrder(value)
    }

    
    let selected_order;


    const intake_rank = (entry) => {
	let { hacked } = entry
	let user_references, value, intake_class

	if (hacked) { // Fucking kill me
	    user_references = last_intake_classes[entry.name].user_references
	    value = last_intake_classes[entry.name].value
	    intake_class = last_intake_classes[entry.name].intake_class
	} else {
	    user_references = entry.user_references
	    value = entry.value
	    intake_class = entry.intake_class
	}

	const offsets = {
	    "deficient": 0.0,
	    "excessive": 100.0,
	    "adequate": 10000.0,
	    "unknown": 1000000.0,
	}

	let rank = offsets[intake_class]
	let percentage = 0.0

	if (intake_class === "deficient") {
	    percentage = value / user_references.lower_reference // TODO: Ordering won't work for proportional to energy food
	} else if (intake_class === "excessive") {
	    percentage = value / user_references.upper_reference
	}

	rank += percentage
	return rank

    }

    const intake_classes = (a, b) => {
	return intake_rank(a) - intake_rank(b)
    }

    let intake_sort = (a, b) => { return 0 ; }

    switch (order) {
    case "natural":
	selected_order = natural
	break;
    case "preferred":
	selected_order = preferred
	break;
    case "abridged":
	selected_order = abridged
	break;
    case "macros":
	selected_order = macros
	break;
    case "sugars":
	selected_order = sugars
	break;
    case "pufa":
	selected_order = pufa
	break;
    case "Déficiences/Excès":
	selected_order = preferred
	intake_sort = intake_classes
	break;

    }
    
    const to_selected_order = (a, b) => {
	const index_of_a = selected_order.indexOf(a.name)
	const index_of_b = selected_order.indexOf(b.name)

	return index_of_a - index_of_b
    }
    
    const unwanted_entries = (entry) => {
	return selected_order.indexOf(entry.name) >= 0
    }

    const { nutrients, unselect, closable, firstHeader, secondHeader, thirdHeader, nutrient_processing, make_nutrient_row, customStyle } = props

    const tableHeaderStyle = {
	"textAlign" : "center"
    }
    
    return <table className="nutrientsTable" style={customStyle}>
	       <thead>
		   <tr>
		       <th style={closable ? {} : tableHeaderStyle}>
			   {
			       closable
				   ? <button className="closeButton" onClick={unselect}> Fermer </button>
			       : thirdHeader
			   }

		       </th>
		       <th style={tableHeaderStyle}>
			   <select onChange={setSelected} defaultValue="Déficiences/Excès" className="formatSelector" id="format-selector">
			       <option value="natural"> Naturel </option>
			       <option value="preferred"> Preféré </option>
			       <option value="abridged"> Abrégé </option>
			       <option value="macros"> Macros </option>
			       <option value="sugars"> Sucres </option>
			       <option value="pufa"> Omégas 3/6/9 </option>
			       <option value="Déficiences/Excès"> Déficiences/Excès </option>
			   </select>
		       </th>		       
		   </tr>
		   <tr style={tableHeaderStyle}>
		       <th> { firstHeader } </th>
		       <th> { secondHeader } </th>
		   </tr>
	       </thead>
	       <tbody>
		   {
		       nutrients
			   .sort(to_selected_order)
			   .filter(unwanted_entries)
			   .map(nutrient_processing)
			   .sort(intake_sort)
			   .map(make_nutrient_row)
		   }
	       </tbody>
	   </table>
}

function FoodNutrientsTable(props) {
    const { unselect, selected_food, foods, total_nutrients } = props
    const food = foods.find((f) => f.name === selected_food)
    const nutrients = food.nutrients.addMissingEntries(total_nutrients)
    const style = {
	"float": "left",
	"marginLeft": "1em"
    }
    const firstHeader = `Composition de ${ selected_food } au prorata de la` 
    const secondHeader = `Quantité : ${ food.quantity * 100 }g`
    const closable = true

    
    const nutrient_processing = (nutrient, index) => {
	const corresponding_total = total_nutrients.findNutrient(nutrient.name).value
	const unit = nutrient.parseUnitWithoutMass()
	let percentage = nutrient.value / corresponding_total * 100
	

	percentage = isNaN(percentage) ? 0.0 : percentage
	
	return {
	    ...nutrient,
	    unit,
	    percentage,
	    hacked: true
	}
    }

    const make_nutrient_row = function (nutrient, index) {
	let { value, unit, percentage } = nutrient

	const name = nutrient.nameWithoutUnit()


	value = isNaN(value) ? 0.0 : value
	
	return (
	    <tr key={ nutrient.name }>
		<td> { name } </td>
		<td> { `${value.toFixed(2)} ${unit} (${percentage.toFixed(2)}% Total)` } </td>
	    </tr>)
    }

    console.log(`Rendering nutrients for ${selected_food}`)

    return <NutrientsTable firstHeader={ firstHeader }
			   secondHeader={ secondHeader }
			   closable={ closable }
			   unselect={ unselect }
			   nutrients={ nutrients }
			   nutrient_processing={ nutrient_processing }
			   make_nutrient_row={ make_nutrient_row }
			   customStyle={ style }
	   />

}

function ProgrammaticalNutritionTable(props) {
    const { nutrients, select } = props
    
    const nutrient_processing = (nutrient, index) => {
	const { user_demographic, user_demographics } = props


	let energyEntry = nutrients.findNutrient("Energie, Règlement UE N° 1169/2011 (kJ/100 g)")
	let energy
	
	if (energyEntry === undefined) {
	    energy = 1.0 // Just so we avoid NaNs
	} else {
	    energy = energyEntry.value
	}

	const energy_in_megajoules = energy / 1000

	// console.log(`Energy: ${energyEntry.value}`)
	// console.log(`Energy in MJ: ${energy_in_megajoules}`)

	
	const value = nutrient.value
	const normalized_key = nutrient.nameWithoutUnit()
	const unit = nutrient.parseUnitWithoutMass()

	// console.log(key, "->", normalized_key, "->",  references[normalized_key])
	let intake_class

	let lower_reference_string = ""
	let upper_reference_string = ""
	let ri_percentage_string = ""

	let user_references = null

	if (references[normalized_key] != undefined) {
	    user_references = references[normalized_key].demographics(user_demographics)

	    // recommend intake percentage
	    let ri_percentage

	    // if (user_references != undefined) {
	    let lower_reference
	    let upper_reference
	    
	    if (user_references.proportional_to_energy) {
		lower_reference = user_references.lower_reference * energy_in_megajoules
		upper_reference = user_references.upper_reference * energy_in_megajoules
	    } else if (user_references.proportional_to_total_energy) {
		const conversion_rate = user_references.unit_to_energy_conversion_rate
		
		const lower_energy_target = user_references.lower_reference * energy
		const upper_energy_target = user_references.upper_reference * energy

		const energy_from_intake = value * conversion_rate

		lower_reference = lower_energy_target / conversion_rate
		upper_reference = upper_energy_target / conversion_rate
	    } else if (user_references.proportional_to_body_weight) {
		lower_reference = user_references.lower_reference * user_demographic.weight
		upper_reference = user_references.upper_reference * user_demographic.weight
	    } else {
		lower_reference = user_references.lower_reference
		upper_reference = user_references.upper_reference
	    }
	    
	    if (lower_reference > value) {
		intake_class = "deficient"
		ri_percentage = value / lower_reference * 100
		lower_reference_string = ' (<' + lower_reference.toFixed(2) + ")"
		ri_percentage_string = `(${ri_percentage.toFixed(2)}%AJR)`
	    } else if (upper_reference < value) {
		intake_class = "excessive"
		// ri_percentage = value / upper_reference * 100
		// upper_reference_string = "(" + upper_reference.toFixed(2) + ' <) '
		// ri_percentage_string = `(${ri_percentage.toFixed(2)}%LSS)`

		ri_percentage = value / lower_reference * 100
		upper_reference_string = "(" + lower_reference.toFixed(2) + ' <) '
		ri_percentage_string = `(${ri_percentage.toFixed(2)}%AJR)`

	    } else {
		ri_percentage = value / lower_reference * 100
		intake_class = "adequate"
		ri_percentage_string = `(${ri_percentage.toFixed(2)}%AJR)`
	    }

	    // console.log(ri_percentage_string)
	    // } else {
	    //     intake_class = "unknown"
	    // }
	} else {
	    intake_class = "unknown"
	}

	last_intake_classes[nutrient.name] = {} 
	last_intake_classes[nutrient.name].intake_class = intake_class
	last_intake_classes[nutrient.name].value = value
	last_intake_classes[nutrient.name].user_references = user_references
	
	return {
	    name: nutrient.name,
	    unitless_name: normalized_key,
	    key: nutrient.name,
	    value,
	    unit,
	    upper_reference_string,
	    lower_reference_string,
	    intake_class,
	    ri_percentage_string,
	    user_references,
	    hacked: false,
	}
    }

    const make_nutrient_row = (entry, index) => {
	const {
	    name,
	    unitless_name,
	    value,
	    key,
	    unit,
	    upper_reference_string,
	    lower_reference_string,
	    intake_class,
	    ri_percentage_string,
	} = entry 
	
	return <NutritionTableEntry key={key}
				    name={name}
				    unitless_name={unitless_name}
				    index={index}
				    value={value}
				    unit={unit}
				    upper_reference_string={upper_reference_string}
				    lower_reference_string={lower_reference_string}
				    intake_class={intake_class}
				    ri_percentage_string={ri_percentage_string}
				    select={select}/>
    }

    const firstHeader = "Nom du nutriment"
    const secondHeader = "Teneur"
    const thirdHeader = "Apport total"
    const closable = false
    const unselect = () => {}
    const style = {
	"float": "left"
    }
    


    return <NutrientsTable firstHeader={ firstHeader }
			   secondHeader={ secondHeader }
			   thirdHeader= { thirdHeader }
			   closable={closable}
			   unselect={unselect}
			   nutrients={nutrients}
			   nutrient_processing={ nutrient_processing }
			   make_nutrient_row={ make_nutrient_row }
			   customStyle={style}
	   />
}

function PerFoodNutrientTable(props) {
    const { foods, name, unselect, total_nutrients } = props

    const nutrientEntry = total_nutrients.findNutrient(name)
    const total = nutrientEntry === undefined ? 0.0 : nutrientEntry.value
    const unit = nutrientEntry === undefined ? "" :  nutrientEntry.parseUnitWithoutMass()
    
    const food_input_height = document.getElementById("foodForm").clientHeight
    const food_list_top_margin = // document.getElementById("foodTable").clientTop
	  parseInt(getComputedStyle(document.getElementById("foodTable"))["margin-top"])

    const offset_height = 0  // + food_input_height + food_list_top_margin

    // console.log(`Offsetting by ${offset_height} = ${food_input_height} + ${food_list_top_margin}`)
    
    const style = {
	"float": "left",
	"marginTop": `${offset_height}px`,
    }

    const tableHeaderStyle = {

    }
	
    
    return <table className="nutrientsTable" style={style}>
	       <thead>
		   <tr>
		       <th>
			   <button className="closeButton" onClick={unselect}> Fermer </button>
		       </th>
		       <th/>

		   </tr>
		   <tr>
		       <th> Apport en { name } par aliment </th>
		       <th style={{ "textAlign" : "center" }}> Teneur totale: { total.toFixed(2) }{unit} </th>
		   </tr>
	       </thead>
	       <tbody>
		   {
		       foods.map((food, index) => {

			   const nutrient = food.nutrients.findNutrient(name)
			   let value 
			   
			   if (nutrient === undefined) {
			       value = 0.0
			   }
			   else {
			       value = nutrient.value
			   }
			   
			   let percentage = (value / total * 100).toFixed(2)
			   percentage = isNaN(percentage) ? 0.0 : percentage

			   return (
			       <tr key={food.name}>
				   <td> {food.name} </td>
				   <td> {`${value.toFixed(2)} ${unit} (${percentage}%)` } </td>
			       </tr>)
		       })
		   }
	       </tbody>
	   </table>
}

class SearchResult extends React.Component {
    state = {
	hover : false,
	focused : false,
    }

    constructor(props) {
	super(props);
	this.onMouseEnter = this.onMouseEnter.bind(this)
	this.onMouseLeave = this.onMouseLeave.bind(this)
	this.onClick = this.onClick.bind(this)
    }

    onMouseEnter() {
	this.setState({
	    hover : true
	})
    }

    onMouseLeave() {
	this.setState({
	    hover : false
	})
    }
    
    onClick() {
	this.props.submit(this.props.result.alim_nom_fr)
    }
    
    render() {

	const { result } = this.props

	
	return <tr onMouseEnter={ this.onMouseEnter }
		   onMouseLeave={ this.onMouseLeave }
		   onClick= { this.onClick }
		   className={ this.state.hover ? "active-search-option" : "" }
		   className="searchResult">
		   <td>
		       <div> <b> { result.alim_nom_fr } </b> </div>
		       <div> <i> { result.ALIM_NOM_INDEX_FR }</i> </div>
		   </td>
	       </tr>
    }
}

class SearchResults extends React.Component {
    render() {	
	const { search_string, submit } = this.props

	const results = foodDB.search(search_string).map(entry => entry.item )

	const style = {
	    "zIndex": "10000",
	    "position": "absolute",
	}

	return <table style={style} className="nutrientsTable">
		   <thead>
		       <tr>
			   <th>
			   </th>
		       </tr>
		   </thead>
		   <tbody>
		       { results.slice(0, 10).map((entry, index) => { return <SearchResult key={ entry.alim_nom_fr } result={entry} submit={submit}/>})}
		   </tbody>
	       </table>
    }    
}

const food_img_placeholder = <img src={logo} className="FoodImage" alt='logo' width='10%'/>;

class FoodForm extends React.Component {

    initialState = {
	name: '',
	quantity: 1,
	input_empty: true,
	input_focused: false,
    }
    
    state = this.initialState

    constructor(props) {
	super(props)
	
	this.onInputEmpty = this.onInputEmpty.bind(this)
	this.onInputNonEmpty = this.onInputNonEmpty.bind(this)
	this.onFocus = this.onFocus.bind(this)
	this.onFocusOut = this.onFocusOut.bind(this)
	this.handleChange = this.handleChange.bind(this)
	this.submit = this.submit.bind(this)
    }

    onFocus() {
	this.setState({
	    input_focused: true
	})
    }

    onFocusOut() {
	this.setState({
	    input_focused: false
	})
    }

    onInputEmpty() {
	this.setState ({
	    input_empty: true
	})
    }

    onInputNonEmpty() {
	this.setState({
	    input_empty: false
	})
    }

    
    handleChange(event) {
	const { name, value } = event.target

	if (name === "name") {
	    if (value === "") {
		this.onInputEmpty()
	    } else {
		this.onInputNonEmpty()
	    }

	    this.setState({
		name : value
	    })

	} else {
	    this.setState({
		quantity: Number(value)
	    })
	}
	
    }

    submit(name) {
	//	console.log('Submitting', this.state.name, this.state.quantity)
	// const { quantity } = this.state
	
	const food = {
	    name,
	    // quantity,
	    quantity: 1,
	}
	
	this.props.callbacks.submit(food)
	this.setState(this.initialState)
    }

    render() {
	const { name, quantity } = this.state
	const { onFocus, onFocusOut, submit } = this
	const { input_empty, input_focused } = this.state

	const table = document.getElementById("foodTable")

	let style = {}
	let divStyle = {}
	if (table)  {
	    let width = getComputedStyle(table)["width"]

	    style = {
		"boxSizing" : "border-box",
		width: width,
	    }
	}

	return (<div id="foodForm" className="foodForm">
		    <input type='text' name="name" className="foodForm" value={name} style={style} placeholder="Rechercher un aliment" autocomplete="off" onChange={this.handleChange} onFocus={onFocus} onBlur={onFocusOut} />
		    { !input_empty ? <SearchResults search_string={name} submit={submit} /> : []}
		</div>)

	
    }
    
}

class FoodDescription extends React.Component {
    state = {
	hover : false,
	focused : false,
	index: 0,
    }

    
    constructor(props) {
	super(props)

	this.state.foods = props.foods
	
	this.deleteFood = this.deleteFood.bind(this)
	this.addFood = this.addFood.bind(this)
	this.onMouseEnter = this.onMouseEnter.bind(this)
	this.onMouseLeave = this.onMouseLeave.bind(this)
	this.onClick = this.onClick.bind(this)
	this.handleChange = this.handleChange.bind(this)
	this.listChange = this.listChange.bind(this)

    }
    
    deleteFood(name) {
	const index = this.state.foods.findIndex(f => f.name == name)

	console.log(`Deleting food ${name} while selected is ${this.state.selected_food}`)
	if (name === this.state.selected_food) {
	    console.log(`Unselecting ${name}`)
	    this.setState({
		selected_food: null
	    })
	    this.props.unselectFood()
	}

	const foods = [...this.state.foods.filter((food, i) => index !== i)]
	
	this.setState({
	    foods: foods
	})

	this.props.submitFoods(foods)
    }


    addFood(food) {
	const index = this.state.foods.findIndex(f => f.name == food.name)

	if (index == -1) {
	    const foods = [...this.state.foods, food]


	    this.setState({
		foods
	    })
	    this.props.submitFoods(foods)
	} else {
	    let foods = [...this.state.foods]

	    foods[index].quantity += food.quantity
	    this.setState({
		foods
	    })
	    this.props.submitFoods(foods)
	}
    }

    onMouseEnter(index) {
	this.setState({
	    hover : true,
	    index
	})
    }

    onMouseLeave() {
	this.setState({
	    hover : false
	})
    }
    
    onClick(name) {
	this.setState({
	    selected_food: name
	})
	this.props.select(name)
    }

    handleChange(event) {
	let { name, value } = event.target
	const foods = [...this.state.foods]

	// if (value === '0') { // TODO Should properly handle String to Number conversion here one day
	//     this.deleteFood(name)
	//     return ;
	// } // The problem with this code is that when we simply want to input small quantities such as 0.125. the value 0 is hit and the entry deleted unexpectedly.
	
	const index = foods.findIndex((f) => f.name === name)

	foods[index].quantity = value

	this.setState({
	    foods
	})
	// this.forceUpdate() // Because foods array reference is never actually changed
	this.props.submitFoods(foods)
    }

    listChange(event) {
	let { name, value } = event.target

	const selected_list = value
	
	this.setState({
	    selected_list
	})

	this.props.selectList(selected_list)
    }

    componentDidMount() {
	this.forceUpdate()
    }

    componentDidUpdate(prevProps) {
	if (prevProps.foods !== this.props.foods) {
	    this.setState({
		foods: this.props.foods
	    })
	}
    }
    
    render() {
	const foods = this.state.foods
	
	const callbacks = {
	    submit: this.addFood, 
	}
	
	const style = {
	    "float": "left",
	}
	
	const tableHeaderStyle = {
	    "textAlign" : "center"
	}


	// TODO: Automatically adjust the steppers' granularities
	return <div style={style}>
		   <FoodForm callbacks={callbacks}/>
		   <table className="nutrientsTable" id="foodTable">
		       <thead>
			   <tr style={tableHeaderStyle}>
			       { /* <th> </th>  */ }
			       <th> Nom de l'aliment </th>
				   <th> Quantité (100g) </th>
			       <th>
				   <select name="selected_list" onChange={this.listChange} className="listSelector" defaultValue="0">
				       <option value="0"> Liste 1 </option>
				       <option value="1"> Liste 2 </option>
				       <option value="2"> Liste 3 </option>
				       <option value="3"> Liste 4 </option>
				       <option value="4"> Liste 5 </option>
				       <option value="5"> Liste 6 </option>
				       <option value="6"> Liste 7 </option>
				       <option value="7"> Liste 8 </option>
				       <option value="8"> Liste 9 </option>
				       <option value="9"> Liste 10 </option>
				   </select>
			       </th>
			   </tr>
		       </thead>
		       <tbody>
			   { foods.map((food, index) => {
			       return <tr key={food.name + " row"}
					  onMouseEnter={ () => this.onMouseEnter(index) }
					  onMouseLeave={ this.onMouseLeave }
					  onClick= { () => this.onClick(food.name) }
					  className={ this.state.hover && index == this.state.index ? "active-search-option" : "" }>
					  
					  <td key={ food.name }> {food.name} </td>
					  <td className="foodQuantity" key={ food.name + " quantity" }>
					      <input name={food.name} value={food.quantity} type="number" onChange={this.handleChange} min="0"/>
					  </td>
					  <td key={ food.name + " delete button"}> <button onClick={(e) => { e.stopPropagation() ; this.deleteFood(food.name) }} className="closeButton"> Supprimer </button> </td>
					  
				      </tr>
			   })}
		       </tbody>
		   </table>
	       </div>
    }
}


function SiteFooter() {

    return <div className="footer">
	       <a href="/mentions-légales.txt"> Mentions légales </a>
	   </div>

}

class App extends React.Component {
    base_foods = [
	{ name: "Flocon d'avoine", quantity: 1 },
	{ name: 'Lait entier, UHT', quantity: 3 },
	{ name: 'Foie, génisse, cuit', quantity: 1 },
	{ name: 'Oeuf, dur', quantity: 3 },
    ];

    base_lists = [
	this.base_foods, // TODO: We should make basic typical food list. Don't know where we would get the information on what's normal though.
	this.base_foods,
	this.base_foods,
	this.base_foods,
	this.base_foods,
	
	this.base_foods,
	this.base_foods,
	this.base_foods,
	this.base_foods,
	this.base_foods,
    ];

    base_demographics = {
	sex: 'male',
	age: '23',
	// weight: 77,
	pregnant: false,
	may_become_pregnant: false,
	breastfeeding: false,
	low_to_medium_period: false,
	heavy_period: false,
	menopaused: false,
    }


    
    state = {
	foods: [],
    }



    constructor(props) {
	super(props)

	const serializedStoredFoodLists = window.localStorage.getItem("food_lists")

	// console.log(serializedStoredFoodLists)
	const storedFoodLists = JSON.parse(serializedStoredFoodLists) 

	if (serializedStoredFoodLists == null || storedFoodLists.length == 0) {
	    this.state.food_lists = this.base_lists
	} else {
	    this.state.food_lists = storedFoodLists
	}

	const serialized_user_demographics = window.localStorage.getItem("user_demographics")

	if (serialized_user_demographics === null) {
	    this.state.user_demographics = this.base_demographics
	} else {
	    this.state.user_demographics = JSON.parse(serialized_user_demographics)

	    console.log("User_demographics retrieved from localStorage, using:")
	    // console.log(this.state.user_demographics)
	}
	
	this.selectNutrient = this.selectNutrient.bind(this)
	this.unselectNutrient = this.unselectNutrient.bind(this)
	this.selectFood = this.selectFood.bind(this)
	this.unselectFood = this.unselectFood.bind(this)
	this.handleResize = this.handleResize.bind(this)
	this.submitDemographics = this.submitDemographics.bind(this)
	this.submitFoods = this.submitFoods.bind(this)
	this.selectList = this.selectList.bind(this)
	

	
	this.state.selected_nutrient = null
	this.state.selected_food = null
	this.state.selected_list = 0


	window.addEventListener("resize", this.handleResize)
    }

    handleResize() {
	this.forceUpdate()
    }

    submitFoods(foods) {
	const lists = [...this.state.food_lists]
	const selected = this.state.selected_list

	lists[selected] = [...foods]
	
	this.setState({
	    food_lists: lists
	})
    }
    
    selectNutrient(selected_nutrient) {
	this.setState({
	    selected_nutrient
	})
    }

    unselectNutrient() {
	this.setState({
	    selected_nutrient: null
	})
    }

    selectFood(selected_food) {
	this.setState({
	    selected_food: selected_food
	})
    }

    unselectFood() {
	this.setState({
	    selected_food: null
	})
    }

    selectList(list) {
	// console.log(`Selected list: ${list}`)
	this.unselectFood()
	this.unselectNutrient()
	this.setState({
	    selected_list: list
	})
    }
    
    submitDemographics(demographics) {
	this.setState({
	    user_demographics: demographics
	})
    }


    updateLocalStorage() {
	const { food_lists, user_demographics } = this.state;

	// TODO: handle possible exception in case of localStorage capacity exceeded
	window.localStorage.setItem("food_lists", JSON.stringify(food_lists))
	window.localStorage.setItem("user_demographics", JSON.stringify(user_demographics))
    }
    
    render() {
	this.updateLocalStorage()


	const foods = this.state.food_lists[this.state.selected_list]

	const user_demographics = getDemographics(this.state.user_demographics)
	// console.log(`Got demographics`)
	// console.log(user_demographics)


	let foods_with_nutrients = foods.map((food) => {
	    const nutrients = foodDB.nutrients(food.name).toProrataOfQuantity(food.quantity)
	    
	    return {
		...food,
		nutrients
	    };
	})

	let empty = plainArrayToNutrientArray([])
	
	let total_nutrients = foods_with_nutrients.map((food) => {
	    return food.nutrients
	}).reduce((acc, nutrients) => {
	    return acc.sum(nutrients)
	}, empty)


	console.log("foods_with_nutrients", foods_with_nutrients)
	console.log("total_nutrients", total_nutrients)


	const selected_food = this.state.selected_food
	const searched_nutrient = this.state.selected_nutrient

	console.log("foods", foods)
	console.log(`Selected food ${this.state.selected_food}`)
	console.log(`Selected nutrient ${searched_nutrient}`)
	
	return (
            <div className="App">
		<h1> Calculateur micronutritionnel </h1>
		<div> Basé sur la <a href="https://ciqual.anses.fr/"> Ciqual 2020</a>  (<a href="https://www.anses.fr/fr">Anses</a>) </div>
		<div> Et sur <a href="https://www.anses.fr/fr/content/les-r%C3%A9f%C3%A9rences-nutritionnelles-en-vitamines-et-min%C3%A9raux"> les recommandations nutritionnelles de l'Anses </a> </div>
			     <div> Ainsi que celles de <a href="https://www.efsa.europa.eu/en/topics/topic/dietary-reference-values"> l'EFSA</a> </div>
		<div> Cliquer sur une ligne pour des informations supplémentaires </div>
		    <UserDescription demographics={this.state.user_demographics} submitDemographics={this.submitDemographics}/>
		<div>
		    <FoodDescription foods={foods} submitFoods={this.submitFoods} select={this.selectFood} unselectFood={this.unselectFood} selectList={this.selectList} />
		    { searched_nutrient ? <PerFoodNutrientTable name={searched_nutrient} foods={foods_with_nutrients} total_nutrients={total_nutrients} unselect={this.unselectNutrient} />: [] }
		</div>
		<div>
		    <ProgrammaticalNutritionTable nutrients={total_nutrients} user_demographic={this.state.user_demographics} user_demographics={user_demographics} select={this.selectNutrient} />
		    { selected_food ? <FoodNutrientsTable total_nutrients={total_nutrients} unselect={this.unselectFood} foods={foods_with_nutrients} selected_food={selected_food} /> : [] }
		</div>
		<SiteFooter/>
            </div>
	);
    }
}

export default App;
